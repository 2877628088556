@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono:wght@400;500;600;700&display=swap');

:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-dark: #0a0b0d;
  --text-light: #ffffff;
  --text-dark: #1a1b1f;
  --font-pixel: 'Press Start 2P', cursive;
  --font-techno: 'Syncopate', sans-serif;
  --font-mono: 'Sometype Mono', monospace;
  --font-heading: 'Archivo Black', sans-serif;
  --font-body: 'Inter', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background: var(--background-dark);
  color: var(--text-light);
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}

.App {
  text-align: center;
  position: relative;
  background-blend-mode: overlay;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}

.noise-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
}

.noise-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  opacity: 0.15;
  filter: url(#noise);
  mix-blend-mode: color-dodge;
}

/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(10, 11, 13, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
  height: 40px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-light);
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: var(--text-light);
  text-decoration: none;
  font-size: 1rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.nav-links a:hover {
  opacity: 1;
}

.launch-btn {
  padding: 0.5rem 1.5rem;
  background: var(--primary-color);
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.launch-btn:hover {
  background-color: #0056b3;
}

/* Hero Styles */
.hero {
  min-height: 100vh;
  padding: 6rem 2rem;
  background: linear-gradient(
    135deg,
    rgba(255, 0, 255, 0.1) 0%,
    rgba(0, 123, 255, 0.1) 100%
  );
  margin-top: 40px;
}

.hero-content {
  max-width: 1400px;
  margin: 0 auto;
}

.hero-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.hero-text {
  text-align: left;
}

.hero-text h1 {
  font-size: 4.5rem;
  margin-bottom: 2rem;
  line-height: 1;
}

.vicky-text {
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: block;
  font-family: var(--font-heading);
  font-size: 3.5rem;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #ff00ff;
}

.ia-text {
  background: linear-gradient(45deg, #00ffff, #ff00ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: block;
  font-family: var(--font-techno);
  font-size: 3rem;
  font-weight: 700;
  color: #00ffff;
}

.hero-description {
  font-family: 'Sometype Mono', monospace;
  font-size: clamp(0.8rem, 1.2vw, 1rem);
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  margin: 1.5rem 0;
  max-width: 800px;
  letter-spacing: 1px;
}

.hero-image {
  position: relative;
}

.hero-image img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(255, 0, 255, 0.2);
}

.hero-image::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  border-radius: 25px;
  z-index: -1;
  opacity: 0.5;
  filter: blur(15px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .hero-text {
    text-align: center;
  }

  .hero-image {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 3.5rem;
  }

  .ia-text {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.1rem;
  }

  .hero-image {
    max-width: 600px;
    margin: 0 auto;
  }
}

/* Buttons */
.primary-btn, .secondary-btn {
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary-btn {
  background: var(--primary-color);
  color: white;
  border: none;
}

.primary-btn:hover {
  background: #0056b3;
}

.secondary-btn {
  background: transparent;
  color: white;
  border: 2px solid var(--primary-color);
}

.secondary-btn:hover {
  background: rgba(0,123,255,0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  
  .hero h1 {
    font-size: 3rem;
  }
  
  .hero h2 {
    font-size: 2rem;
  }
  
  .hero-buttons {
    flex-direction: column;
  }
}

/* Twitter Section Styles */
.twitter-section {
  flex: 1;
  width: 400px;
  min-width: 400px;
  margin-top: 1rem;
  position: relative;
  height: auto;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 1.5rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.twitter-container {
  width: 600px;
  background: rgba(20, 21, 23, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.twitter-tweet {
  max-width: 550px !important;
  width: 100% !important;
  margin: 0 auto !important;
  max-height: 400px !important;
  overflow: hidden !important;
}

.twitter-tweet iframe {
  max-height: 400px !important;
  border-radius: 12px !important;
}

/* Responsive adjustments for Twitter section */
@media (max-width: 1024px) {
  .twitter-section {
    width: 100%;
    max-width: 500px;
    margin: 2rem auto;
    position: static;
    min-width: unset;
  }

  .twitter-container {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .twitter-section {
    width: 100%;
    min-width: unset;
    margin: 1rem auto;
    padding: 1rem;
  }
}

.content-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-content {
  width: 100%;
  margin: 0;
  padding: 0;
}

.roadmap-container {
  width: 100%;
  min-height: 100vh;
  padding: 2rem 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.roadmap-title {
  font-family: var(--font-heading);
  font-size: clamp(2.5rem, 5vw, 4rem);
  margin: 0 auto 4rem;
  text-align: center;
  width: 100%;
  max-width: 1400px;
  padding: 0 2rem;
  background: linear-gradient(45deg, #fff, #ccc);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmap-steps {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.roadmap-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  width: 100%;
}

.roadmap-step:nth-child(even) {
  flex-direction: row-reverse;
}

.step-image {
  flex: 0 0 45%;
  max-width: 600px;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

/* Separate transitions for fade-in and fade-out */
.fade-out img {
  opacity: 0;
  transition: opacity 2s ease-out;  /* Slow fade out */
}

.fade-in img {
  opacity: 1;
  transition: opacity 0.3s ease-in;  /* Quick fade in */
}

.step-content {
  flex: 0 0 45%;
  max-width: 600px;
  text-align: left;
}

.roadmap-step:nth-child(even) .step-content {
  text-align: right;
}

.step-number {
  font-family: var(--font-heading);
  font-size: clamp(2rem, 3vw, 2.8rem);
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step-description {
  font-family: 'Sometype Mono', monospace;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
  padding: 0 2rem;
}

.step-description p {
  margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .roadmap-steps {
    gap: 5rem;
  }
  
  .roadmap-step {
    gap: 3rem;
  }
}

@media (max-width: 968px) {
  .roadmap-steps {
    gap: 4rem;
  }

  .roadmap-step {
    flex-direction: column !important;
    gap: 2rem;
  }

  .step-image, .step-content {
    flex: 0 0 100%;
    max-width: 500px;
  }
}

.roadmap-footer {
  width: 100%;
  max-width: 1100px;
  margin: 3rem auto 0;
  text-align: center;
}

.roadmap-footer p {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Sometype Mono', monospace;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  line-height: 1.8;
  letter-spacing: 0.02em;
  font-weight: 400;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .roadmap-title {
    padding: 0;
    font-size: 2rem;
  }
  
  .roadmap-step {
    padding: 1.5rem;
  }
}

.hero-content-wrapper {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}

.bio-section {
  flex: 1;
}

.hero-right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.hero-image {
  margin-bottom: 2rem;
}

.hero-wrapper {
  display: flex;
  gap: 3rem;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
  align-items: flex-start;
}

.hero-main {
  flex: 4;
  max-width: 1200px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header-section {
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.vicky-text, .ia-text {
  font-family: var(--font-heading);
  line-height: 1;
  text-transform: none;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.vicky-text {
  font-size: 5rem;
  letter-spacing: -0.02em;
}

.ia-text {
  font-size: 4.5rem;
  font-weight: 700;
}

/* Add some text shadow for better visibility */
.vicky-text, .ia-text {
  filter: drop-shadow(0 0 10px rgba(255, 0, 255, 0.3));
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .vicky-text {
    font-size: 4rem;
  }
  
  .ia-text {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .vicky-text {
    font-size: 3rem;
  }
  
  .ia-text {
    font-size: 2.5rem;
  }
}

.content-grid {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 2rem;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}

.text-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  text-align: center;
}

.hero-description {
  font-family: var(--font-body);
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 600px;
  text-align: center;
}

.buy-token-link {
  font-family: var(--font-techno);
  font-weight: 700;
  letter-spacing: 0.1em;
  padding: 1rem 2rem;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  border-radius: 8px;
  text-decoration: none;
  color: #000;
  text-align: center;
  transition: all 0.3s ease;
  width: fit-content;
}

.hero-image {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(255, 0, 255, 0.2);
}

.hero-image img {
  width: 100%;
  height: auto;
  display: block;
}

.info-banner {
  font-family: var(--font-pixel);
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

@media (max-width: 1400px) {
  .hero-wrapper {
    gap: 2rem;
  }
  
  .hero-main {
    flex: 3;
  }
}

@media (max-width: 1200px) {
  .content-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .text-content {
    text-align: center;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .hero-wrapper {
    flex-direction: column;
  }
  
  .twitter-section {
    width: 100%;
    max-width: 500px;
    margin: 2rem auto;
    position: static;
    min-width: unset;
  }
}

.hero-tagline {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.main-tagline {
  font-family: var(--font-heading);
  font-size: 3rem;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.sub-tagline {
  font-family: var(--font-heading);
  font-size: 2rem;
  line-height: 1.1;
  color: #ff00ff;
  opacity: 0.9;
  margin-top: 0.5rem;
}

.hero-description {
  font-family: var(--font-body);
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 600px;
  text-align: center;
}

@media (max-width: 768px) {
  .main-tagline {
    font-size: 2.2rem;
  }
  
  .sub-tagline {
    font-size: 1.6rem;
  }
  
  .hero-description {
    font-size: 1.1rem;
  }
}

/* Music Platforms Section */
.music-platforms {
  margin-top: 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.platforms-title {
  font-family: var(--font-heading);
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-platforms {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.platform-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  border-radius: 8px;
  text-decoration: none;
  color: #fff;
  font-family: var(--font-body);
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.platform-link.disabled {
  opacity: 0.6;
  background: rgba(255, 255, 255, 0.05);
  cursor: not-allowed;
}

.platform-link:not(.disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.platform-link i {
  font-size: 1.2rem;
}

.additional-platforms {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;
}

.platforms-subtitle {
  font-family: var(--font-heading);
  font-size: 0.9rem;
  color: #fff;
  margin-bottom: 0.75rem;
  opacity: 0.8;
}

.platform-list {
  font-family: var(--font-body);
  font-size: 0.8rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.6);
  text-align: justify;
}

@media (max-width: 1024px) {
  .main-platforms {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .main-platforms {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .main-platforms {
    grid-template-columns: 1fr;
  }
}

/* Alternative noise effect */
.noise-overlay {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200%;
  z-index: 9999;
  pointer-events: none;
  background: transparent;
  opacity: 0.3;
  filter: url(#noise);
}

/* Moving banner at the top */
.marquee-container {
  width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
}

.marquee-content {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  animation: centerMarquee 15s linear infinite;
  color: #ff00ff;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 0;
}

@keyframes centerMarquee {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

/* CA Banner */
.ca-banner {
  width: 100%;
  padding: 1rem;
  margin: -1.5rem auto 1rem auto;
  max-width: 90%;
  background: linear-gradient(90deg, 
    rgba(0, 255, 255, 0.1) 0%,
    rgba(255, 0, 255, 0.1) 100%);
  border: 1px solid rgba(0, 255, 255, 0.3);
  border-radius: 8px;
  text-align: center;
  font-size: 1.2rem;
  font-family: var(--font-mono);
  font-weight: 500;
  color: #fff;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
  user-select: all;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ca-banner:hover {
  background: linear-gradient(90deg, 
    rgba(0, 255, 255, 0.15) 0%,
    rgba(255, 0, 255, 0.15) 100%);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ca-banner {
    margin: -1rem auto 0.5rem auto;
  }
  
  .marquee-content {
    font-size: 0.9rem;
  }
}

/* Hide all scrollbars while maintaining scroll functionality */
html, body {
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

/* Hide webkit scrollbar */
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

/* Also apply to main container if needed */
.App {
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.App::-webkit-scrollbar {
  display: none;
}

/* Update or add this class */
.hero-main-description {
  font-family: 'Sometype Mono', monospace;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  line-height: 1.8;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 1.5rem 0;
  font-weight: 500;
}

.press-start {
  font-family: 'Press Start 2P', cursive;
  font-size: clamp(0.8rem, 1vw, 1rem);
  line-height: 1.8;
  letter-spacing: 1px;
}

.bio-description {
  font-family: 'Sometype Mono', monospace;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 1.5rem auto;
  text-align: center;
}

.title-container {
  font-size: 6rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 2rem;
  letter-spacing: -0.02em;
}

.vicky-text {
  display: block;
  font-size: 8rem;
  background: linear-gradient(to right, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(255, 0, 255, 0.3);
  text-transform: none;
}

.ia-text {
  font-size: 7rem;
  color: #fff;
  margin-left: 0.5rem;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .title-container {
    font-size: 4rem;
  }
  .vicky-text {
    font-size: 6rem;
  }
  .ia-text {
    font-size: 5rem;
  }
}

.platform-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.platform-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.platform-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #007bff; /* Or your preferred color */
}

.platform-name {
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  /* Title adjustments */
  .title-container {
    font-size: 3rem;  /* Reduced from 4rem */
  }
  
  .vicky-text {
    font-size: 4rem;  /* Reduced from 6rem */
  }
  
  .ia-text {
    font-size: 3.5rem;  /* Reduced from 5rem */
  }

  /* Content layout */
  .hero-wrapper {
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
  }

  /* Twitter section */
  .twitter-section {
    width: 100%;
    min-width: unset;
    margin: 0 auto;
  }

  /* Platform grid */
  .platform-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* General spacing */
  .hero-content {
    gap: 1rem;
  }

  /* Text sizes */
  .hero-description {
    font-size: 0.9rem;
    margin: 1rem 0;
  }
}

/* Add smaller screen adjustments */
@media (max-width: 480px) {
  .title-container {
    font-size: 2.5rem;
  }
  
  .vicky-text {
    font-size: 3rem;
  }
  
  .ia-text {
    font-size: 2.5rem;
  }

  /* Adjust padding and margins */
  .hero-wrapper {
    padding: 0.5rem;
  }

  /* Platform adjustments */
  .platform-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  /* Banner adjustments */
  .ca-banner {
    font-size: 0.9rem;
    padding: 0.75rem;
  }

  .marquee-content {
    font-size: 0.8rem;
  }
}

/* Add landscape mode adjustments */
@media (max-height: 600px) and (orientation: landscape) {
  .hero-wrapper {
    padding-top: 60px;
  }

  .title-container {
    margin-bottom: 1rem;
  }

  .hero-description {
    margin: 0.5rem 0;
  }
}

/* Mobile and Tablet Responsive Fixes */
@media (max-width: 768px) {
  /* Hero section fixes */
  .hero-wrapper {
    flex-direction: column;
    padding: 1rem;
  }

  .content-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .hero-image {
    max-width: 100%;
    margin: 0 auto;
  }

  /* Title size adjustments */
  .title-container {
    font-size: 2rem;
  }

  .vicky-text {
    font-size: 2.5rem;
  }

  .ia-text {
    font-size: 2rem;
  }

  /* Description text adjustments */
  .hero-description {
    font-size: 1rem;
    padding: 0 1rem;
  }

  /* Platform grid adjustments */
  .main-platforms {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }

  .platform-link {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  /* Twitter section fixes */
  .twitter-section {
    width: 100%;
    min-width: unset;
    margin: 1rem auto;
    padding: 1rem;
  }

  /* CA Banner adjustments */
  .ca-banner {
    font-size: 0.8rem;
    padding: 0.75rem;
    margin: 0.5rem auto;
    max-width: 95%;
    word-break: break-all;
  }
}

/* Additional tablet-specific adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero-wrapper {
    padding: 1.5rem;
  }

  .content-grid {
    gap: 2rem;
  }

  .title-container {
    font-size: 2.5rem;
  }

  .vicky-text {
    font-size: 3rem;
  }

  .ia-text {
    font-size: 2.5rem;
  }
}

/* Fix for very small screens */
@media (max-width: 360px) {
  .title-container {
    font-size: 1.5rem;
  }

  .vicky-text {
    font-size: 2rem;
  }

  .ia-text {
    font-size: 1.5rem;
  }

  .main-platforms {
    grid-template-columns: 1fr;
  }

  .platform-link {
    padding: 0.5rem;
  }
}

/* Fix viewport issues */
@media screen and (max-width: 768px) {

  .roadmap-step {
    flex-direction: column !important;
    text-align: center;
    gap: 1rem;
    padding: 0;
  }

  .step-image, .step-content {
    flex: 1;
    max-width: 100%;
  }

  .step-content {
    text-align: center !important;
  }

  .roadmap-step:nth-child(even) .step-content {
    text-align: center !important;
  }
}
.twitter-widget iframe {
  height: 300px !important;
}


